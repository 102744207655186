/**
 * Created by lewellyn on 4/9/18.
 */
import React from 'react';
import {FormControl, FormGroup, Form,DropdownButton,Dropdown,Table} from 'react-bootstrap'
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EbasePanel from '../components/common/EbasePanel'
import EButton from "../components/common/EButton"
import sitedata from '../utils/sitedata'
import {withTranslation} from "react-i18next";
import EBSwitch from "../components/common/EBSwitch";

class UserManageView extends React.Component {

    constructor(props){
        super(props);

        this.valueForLabel = this.valueForLabel.bind(this);
        this.nameForRole = this.nameForRole.bind(this);
        this.accessSelected = this.accessSelected.bind(this);
        this.getRoleForClient = this.getRoleForClient.bind(this);
        this.getUserLibraryAccess = this.getUserLibraryAccess.bind(this);
        this.makeDropDown = this.makeDropDown.bind(this);
        this.makeLibraryDropDown = this.makeLibraryDropDown.bind(this);
        this.libraryAccessTypeString = this.libraryAccessTypeString.bind(this);

        // console.log("UserManageView ",props);
        let userId = props.match.params.userId;

        let user = {userId: "new", username: "", email: "", firstname: "", lastname: "",password:null, active: true};
        if(userId !== "new" && this.props.theusers){
            for (let i=0;i<this.props.theusers.length;i++){
                let u = this.props.theusers[i];
                if (u.userId === userId){
                    user = u;
                    break;
                }
            }
        }

        const currentClient = sitedata.getClient(props.siteData);

        this.state={user:user,userLibraries:this.props.manageUserLibraries, currentClient: currentClient};



    }

    componentWillMount(){
        // console.log("ManageView:: will mount");
        // console.log(this.props);
        // console.log("----");

        let userId = this.props.match.params.userId;
        if(userId !== undefined && userId !== null){

            if(userId.toLowerCase() !== "new"){

                this.props.loadCurrentLibraries(userId);
            }
        }
    }

    componentWillReceiveProps(props){
        // console.log("ManageView:: will receive props");
        // console.log(props);

        if(this.state.userLibraries === null || this.state.userLibraries === undefined){
            if(props.manageUserLibraries !== null && props.manageUserLibraries !== undefined){
                this.setState({userLibraries:props.manageUserLibraries});
            }
        }
    }

    onFieldChange(field,event){
        //console.log("fieldChanged");
        //console.log(field);
        // console.log(event);
        let value = event.target.value;


        let u = this.state.user;
        if(field === "Username"){
            u.username = value;
            this.setState({user:u});
        }
        if(field === "First Name"){
            u.firstname = value;
            this.setState({user:u});
        }
        if(field === "Surname"){
            u.lastname = value;
            this.setState({user:u});
        }
        if(field === "Password"){
            u.password = value;
            this.setState({user:u});
        }
        if (field === 'active'){
            u.active = value;
            this.setState({user:u});
        }

        this.checkCanUpdate();
    }


    valueForLabel(label,user){
        let value = "";
        //["Username","First Name","Surname","Password"];
        if(label === "Username"){
            return user.username;
        }
        if(label === "First Name"){
            return user.firstname;
        }
        if(label === "Surname"){
            return user.lastname;
        }
        if(label === "Password"){
            if(user.password !== null && user.password !== undefined){}
            return user.password;
        }
        return value;
    }

    onUpdateClick(){

        console.log(this.state);
        let update = this.state.user;




        let userInfo = {
            userId:update.userId,
            username:update.username,
            email:update.username,
            firstname:update.firstname,
            lastname:update.lastname,
            password:update.password,
            active: update.active,
            access:this.state.userLibraries
            };

        //add any access

        this.props.updateUser(userInfo);



    }

    validateField(field,value){
        //console.log("validate "+field+" | "+value);
        let user = this.state.user;

        let isNew = user.userId === "new";

        if(field === "Username" || field === "First Name"){

            if (value === null || value.trim().length > 0){
                return null;
            }else{
                return "error";
            }

        }

        if(isNew) {
            if (field === "Password") {
                if(value === null){
                    return "error";
                }

                if (value.length > 0){
                    return null;
                }else{
                    return "error";
                }
            }
        }

        if(field === "Access"){
            if (value === null || value === undefined){
                return "error";
            }
        }

        return null;

    }

    checkCanUpdate(){

        let user = this.state.user;
        let status = false;

        if (user.firstname.trim().length > 0 && user.username.trim().length > 0){

            status = true;

            if(user.userId === "new"){

                if(user.password === null){
                    status = false;
                }else {
                    status = user.password.length > 0;
                }
            }
        }

        if(this.state.userLibraries !== undefined && this.state.userLibraries !== null){
            status = this.state.userLibraries.length > 0;
        }else{
            status = false;
        }

        return status
    }

    accessSelected(key){
         // console.log("access selected "+key);

        let info = key.split("|");
        let role = info[0];
        let clientId = info[1];
        let datasetId = info[2];

         // console.log("accessSelected set dataset "+datasetId + " for client "+clientId+" to "+role);

        let userLibraries = Object.assign([],this.state.userLibraries);

        // console.log("userLibariy before");
        // console.log(userLibraries);

        let libraryAccess = "NONE";
        if(role === "localAdmin" || role === "localContent"){
            libraryAccess = "WRITE";
        }
        if(role === "envidUser" || role === "readOnly" || "stakeholder"){
            libraryAccess = "READONLY";
        }

        if(userLibraries == null){

            userLibraries = [{client:{clientId:clientId},libraries:[{datasetId:datasetId,userDatasetAccessType:libraryAccess}], role:role}];

        }else{
            let clientLib = null;
            for(let i=0;i<userLibraries.length;i++){
                let clb = userLibraries[i];

                if (clb.client.clientId === clientId){
                    clientLib = clb;

                    clb.role = role;
                    //clientDatasetAccessType
                    for(let j=0;j<clb.libraries.length;j++){
                        let l = clb.libraries[j];
                        if(l.datasetId === datasetId){
                            l.userDatasetAccessType = libraryAccess;
                        }
                    }
                }
            }

            if(clientLib === null){
                // console.log("client not found, add new one");

                const clib = sitedata.getClientLibraries(this.props).find(clb => clb.client.clientId === clientId);
                const nextLibs = clib.libraries.map(lib => ({
                    datasetId: lib.datasetId,
                    userDatasetAccessType: libraryAccess
                }));

                clientLib = {client:{clientId:clientId},libraries:nextLibs, role:role};

                userLibraries.push(clientLib);
            }
        }

        let filtered = userLibraries.filter( clb => clb.role !== "none")

        // console.log("userLibariy after");
        // console.log(filtered);

        this.setState({userLibraries: filtered});
    }

    // key is a string separated by '|' with <ACCESS>|<clientId>|<datasetId>
    libraryAccessSelected(key){
        // console.log("library accesss selected");
        // console.log(key);
        let info = key.split("|");
        let access = info[0];
        let clientId = info[1];
        let datasetId = info[2];

        // console.log("set dataset "+datasetId + " for client "+clientId+" to "+access);

        let userLibraries = Object.assign([],this.state.userLibraries);

        for(let i=0;i<userLibraries.length;i++){
            let clb = userLibraries[i];
            if (clb.client.clientId === clientId){
                for(let j=0;j<clb.libraries.length;j++){
                    let l = clb.libraries[j];
                    if(l.datasetId === datasetId){
                        l.userDatasetAccessType = access;
                    }
                }
            }
        }

        // console.log("libraries", userLibraries);

        this.setState({userLibraries: userLibraries});
    }

    nameForRole(role){

        const {t} = this.props;

        if (role === null || role === undefined || role === "none"){
            return t("No Access");
        }

        //let map = {fullAccess:"Full Access",projectReadOnly: "Project User", readOnly: "Read Only", superUser:"Super User"};
        let map = {localAdmin:t("Company Admin"),localContent: t("Company User"),envidUser:t("Project User"), readOnly: t("Read Only"), superUser:t("Super User"),stakeholder: t("Stakeholder")};

        let name = map[role];

        if(name !== undefined){
            return name;
        }

        return role;
    }


    getRoleForClient(clientId){

        let libs = this.state.userLibraries;
        if(libs === null || libs === undefined){
            return null;
        }
         // console.log("get access");
         // console.log(libs);

        let role = null;
        for(let i=0;i<libs.length;i++){

            let clb = libs[i];

            // console.log("client");
            // console.log(clb.client);

            if (clb.client.clientId === clientId){

                role = clb.role;
            }
        }
        return role;
    }

    getUserLibraryAccess(clientId,datasetId){
        let libs = this.state.userLibraries;
        if(libs === null || libs === undefined){
            return null;
        }

        let access = null;
        for(let i=0;i<libs.length;i++){
            let clb = libs[i];
            if (clb.client.clientId === clientId){
                for(let j=0;j<clb.libraries.length;j++){
                    let lib = clb.libraries[j];
                    if(lib.datasetId === datasetId){
                        access = lib.userDatasetAccessType;
                    }
                }
            }
        }

        return access;
    }

    makeDropDown(accessRole,key)
    {
        const {t} = this.props;

        let roleTitle = this.nameForRole(accessRole);

        let style = "default";
        if(accessRole !== null && accessRole !== "none"){
            style = "primary";
        }

        return (
                <DropdownButton
                    id={key}
                    bsstyle={style}
                    variant={accessRole === null || accessRole === "none" ? "outline-secondary" : undefined}
                    title={roleTitle}
                    disabled={accessRole === "superUser"}
                    onSelect={this.accessSelected.bind(this)}
                    >
                    <Dropdown.Item eventKey={"none|"+key}
                                   active={accessRole === null || accessRole === "none"}>{t("No Access")}</Dropdown.Item>
                    <Dropdown.Item eventKey={"localAdmin|"+key}
                                   active={accessRole === "localAdmin"}>{t("Company Admin")}</Dropdown.Item>
                    <Dropdown.Item eventKey={"localContent|"+key}
                                   active={accessRole === "localContent"}>{t("Company User")}</Dropdown.Item>
                    <Dropdown.Item eventKey={"envidUser|"+key}
                                   active={accessRole === "envidUser"}>{t("Project User")}</Dropdown.Item>
                    <Dropdown.Item eventKey={"readOnly|"+key}
                                   active={accessRole === "readOnly"}>{t("Read Only")}</Dropdown.Item>
                    <Dropdown.Item eventKey={"stakeholder|"+key}
                                   active={accessRole === "stakeholder"}>{t("Stakeholder")}</Dropdown.Item>

                </DropdownButton>

        );
    }

    libraryAccessTypeString(type){

        const {t} = this.props;

        if(type === null || type === undefined || type === "NONE"){
            return t("None");
        }
        if(type === "READONLY"){
            return t("Read Only")
        }
        if(type === "WRITE"){
            return t("Write");
        }

        return "";
    }

    makeLibraryDropDown(clientAccessType,userAccessType,key){

        const {t} = this.props;

        // console.log("makeLibraryDropDown");

        // console.log("");


        let title = "";
        if(clientAccessType === "READONLY"){
            title = t("Read Only");
        }else{
            title = this.libraryAccessTypeString(userAccessType);
        }

        return(
            <DropdownButton id={key} title={title} disabled={clientAccessType === "READONLY"} onSelect={this.libraryAccessSelected.bind(this)}>
                <Dropdown.Item eventKey={"READONLY|"+key} active={userAccessType === "READONLY"}>{t("Read Only")}</Dropdown.Item>
                <Dropdown.Item eventKey={"WRITE|"+key} active={userAccessType === "WRITE"}>{t("Write")}</Dropdown.Item>
                <Dropdown.Item eventKey={"NONE|"+key} active={userAccessType === null}>{t("None")}</Dropdown.Item>
            </DropdownButton>
        );
    }

    getClientAccessToLibrary(clientId,datasetId){

        let access = "NONE";

        let clientLibraries = sitedata.getClientLibraries(this.props);

        if(clientLibraries !== null && clientLibraries !== undefined){

        }

        return access;
    }


    render() {

        const {t} = this.props;

        // console.log("User Manage View");
        // console.log(this.props);
        //console.log(this.state);
        // console.log("user id: " + this.props.match.params.userId);

        let user = this.state.user;//{userId: "new", username: "", email: "", firstname: "", lastname: ""};
        var actionButtonText = t("Create User");
        let nameCrumb = t("New");
        let isNewUser = true;

        let currentLibraries = sitedata.getClientLibraries(this.props).filter(clb => clb.client.clientId !== this.state.currentClient.clientId); //All the libraries the session user has access to
        currentLibraries.sort((a,b) => {return a.client.name.localeCompare(b.client.name)});

        // putting the currently selected client libs at the top.
        const currentClientLibs = sitedata.getClientLibraries(this.props).filter(clb => clb.client.clientId === this.state.currentClient.clientId);
        currentLibraries = [...currentClientLibs, ...currentLibraries];


        if (user.userId !== "new") {
            isNewUser = false;
            actionButtonText = t("Update User");
            nameCrumb = user.username;

        }

        let actionEnabled = this.checkCanUpdate();

        // console.log("user:");
        // console.log(user);

        let subtitle = "";
        let isSaving = false;

        if (this.props.savingUser !== undefined) {
            if (this.props.savingUser) {
                subtitle = t("Saving") + "...";
                isSaving = true;
            }else{
                subtitle = "";
                isSaving = false;
            }
        }


        let fgClass = "";//"row";
        let labelClass = "";//"col-lg-2 text-right";
        let inputClass = "";//"col-lg-9";
        let inputStyle = {};//{marginLeft:"30px", marginRight:"30px"};
        var style = {marginBottom: "20px"};


        let labels = ["Username", "First Name", "Surname", "Password"];
        let inputs = [];

        for (var i = 0; i < labels.length; i++) {

            let l = labels[i];
            let type = "text";
            if (l === "Password") {
                type = "password";
            }
            let value = this.valueForLabel(l, user);
            let input = (
                <FormGroup key={l} className={fgClass} style={style} validationState={this.validateField(l, value)}>
                    <Form.Label className={labelClass}>{t(l)}</Form.Label>
                    <div className={inputClass} style={inputStyle}>
                        <FormControl type={type}
                                     style={{width: "300px", fontSize: "13px"}}
                                     value={value}
                                     onChange={this.onFieldChange.bind(this, l)}
                                     disabled={isSaving}
                        />
                    </div>
                </FormGroup>
            );
            inputs.push(input);
        }

        inputs.push(
          <Form.Group className="" style={{marginBottom: "20px", display:"flex",flexDirection:"row",justifyContent:"flex-start", alignItems:"center"}}>
              <Form.Label column={'sm'}>User Active</Form.Label>
              <EBSwitch checked={user.active} onChange={(checked) => this.onFieldChange('active', {target: {value:checked}})}/>
          </Form.Group>
        )

        //console.log(this.props.savingUser);
        //console.log("saving: "+saving);


        let formContent = (
            <EbasePanel>
                <FormGroup key="xyz" className={fgClass} style={style}>
                    {inputs}
                </FormGroup>

                <div style={{width:300,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <EButton onClick={this.props.cancelClick} type="standard">{t('Cancel')}</EButton>
                    <EButton onClick={this.onUpdateClick.bind(this)} type="add" disabled={!actionEnabled || isSaving}>{actionButtonText}</EButton>
                </div>
            </EbasePanel>

        );

        let librarySelect = null;

        if (this.props.savingUser === false && this.props.savedUser !== undefined){

            let info = t("User Updated Successfully");

            if(isNewUser){
                info = t("User Created Successfully");
            }

            //saving new user has been a success
            formContent = (
                <div>
                    <div>
                        <h3>{info}</h3>
                    </div>
                    <div style={{width:300,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <EButton onClick={this.props.cancelClick} type="standard">{t('Done')}</EButton>
                    </div>
                </div>

            );

        }else {

            let canGrantAccess = sitedata.checkWriteAccess(this.props, "user");

            let height = this.props.dimensions.window.height - this.props.dimensions.extras.height;

            if (canGrantAccess) {



                //console.log(this.props.theusers);

                // console.log("current Libraries");
                //  console.log(currentLibraries);
                //
                //  console.log("userLibraries:");
                //  console.log(this.state.userLibraries);

                let rows = [];
                let body = [];

                if (currentLibraries == null) {
                    body.push(<thead>
                    <tr>
                        <td>{t("No Libraries")}</td>
                    </tr>
                    </thead>);
                } else {

                    if (currentLibraries.length === 0) {
                        body.push(<thead>
                        <tr key="UserHeader">
                            <td>{t("No Libraries")}</td>
                        </tr>
                        </thead>);
                    } else {

                        body.push(<thead>
                        <tr key="UserHeader">
                            <th>{t("User Type")}</th>
                            <th>{t("Company")}</th>
                            <th>{t("Library")}</th>
                            <th>{t("Library Access")}</th>
                        </tr>
                        </thead>);

                        for (let cid in currentLibraries) {

                            let clientLibrary = currentLibraries[cid];


                            let libs = clientLibrary.libraries;
                            let client = clientLibrary.client;
                            let foundStlye = {};

                            for (let j = 0; j < libs.length; j++) {

                                let lib = libs[j];
                                if (lib.archived === true) {
                                    continue;
                                }

                                let accessRole = this.getRoleForClient(client.clientId);
                                // if (accessRole !== null) {
                                //     foundStlye = {backgroundColor: 'green'};
                                // }

                                let userKey = client.clientId + "|" + lib.datasetId;
                                let userDropDown = this.makeDropDown(accessRole, userKey);

                                let libOption = "";
                                if(lib.clientDatasetAccessType === "READONLY"){
                                    libOption = t("Read Only");
                                }else if(accessRole !== null && accessRole !== "none"){
                                    libOption = this.makeLibraryDropDown(lib.clientDatasetAccessType,this.getUserLibraryAccess(client.clientId,lib.datasetId),client.clientId + "|"+lib.datasetId);
                                    // console.log("accessRole");
                                    // console.log(accessRole);
                                }


                                if (j === 0) {
                                    rows.push(<tr key={userKey}>
                                        <td>{userDropDown}</td>
                                        <td>{client.name}</td>
                                        <td style={foundStlye}>{lib.desc}</td>
                                        <td>{libOption}</td>
                                    </tr>);
                                } else {
                                    rows.push(<tr key={userKey}>
                                        <td></td>
                                        <td></td>
                                        <td style={foundStlye}>{lib.desc}</td>
                                        <td>{libOption}</td>
                                    </tr>);
                                }
                            }

                        }

                        body.push(<tbody>{rows}</tbody>);
                    }
                }


                librarySelect = (

                    <div style={{height: height - 140, width: '100%', overflow: "scroll", marginLeft: 40}}>
                        <h2>{t('Access')}</h2>
                        <Table className="table table-hover no-margins">
                            {body}
                        </Table>
                    </div>
                );


            }
        }



        return (

            <div>
                <ContainerHeader title={nameCrumb} crumbs={['/','/users', t('Manage')]}  dimensions={this.props.dimensions} subtitle={subtitle}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <EbasePanel>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        {formContent}
                        {librarySelect}
                    </div>
                    </EbasePanel>
                </PageWrapper>
            </div>

        );


    }

}


export default withTranslation()(UserManageView)
